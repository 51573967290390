<div class="flex-wrapper">
  <div class="center-page">
    <section id="error">
      <header>
        <h1>
          {{ 'rspErrorPageTitle' | translate }}
        </h1>
      </header>
      <div>
        <p>{{ 'rspErrorPageBody' | translate }}</p>
      </div>
      <button class="btn btn-primary float-end" routerLink="/welcome" routerLinkActive="active" type="button">
        {{ 'rspErrorPageGoBackToWelcome' | translate }}
      </button>
    </section>
  </div>
</div>

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { iif, Observable, of } from 'rxjs';
import { UserContextService } from '../../services/user-context-service/user-context.service';
import { switchMap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { CookieKey } from '../../models/cookie-key.model';

@Injectable({ providedIn: 'root' })
export class RouteGuard {
  constructor(private router: Router, private userContextService: UserContextService, private cookieService: CookieService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userContextService
      .getAuthenticatedUser()
      .pipe(
        switchMap(userContext =>
          iif(
            () => userContext !== null && this.cookieService.check(CookieKey.IsLoggedIn),
            of(true),
            of(this.router.parseUrl('/authenticate'))
          )
        )
      );
  }
}

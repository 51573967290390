<div id="confirm">
  <h1>
    {{ 'rspConfirmHeading' | translate }}
  </h1>
  <div id="confirm-information" class="confirm-information" [innerHTML]="'rspConfirmInformation' | translate"></div>
  <div>
    <input id="has-declared-accuracy-checkbox" type="checkbox" [(ngModel)]="hasDeclaredAccuracy" />
    <label for="has-declared-accuracy-checkbox" [innerHTML]="'rspConfirmCheckboxAccurate' | translate"></label>
  </div>
  <div>
    <input id="has-confirmed-checkbox" type="checkbox" [(ngModel)]="hasConfirmed" />
    <label for="has-confirmed-checkbox" [innerHTML]="'rspConfirmCheckboxConfirm' | translate"></label>
  </div>
  <div class="d-flex justify-content-between">
    <button id="back-btn" class="btn btn-secondary" (click)="goBack()" type="button">
      {{ 'rspConfirmButtonBack' | translate }}
    </button>
    <button id="confirm-btn" class="btn btn-primary" type="button" [disabled]="!hasDeclaredAccuracy || !hasConfirmed" (click)="confirm()">
      {{ 'rspConfirmButtonContinue' | translate }}
    </button>
  </div>
</div>

import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config-service/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class UrlConfigService {
  baseContextPath: string = '';
  authServiceBasePath: string = '';
  themingServiceBasePath: string = '';

  constructor(private appConfigService: AppConfigService) {
    this.baseContextPath = this.appConfigService.configData.api.urlPrefix;
    this.authServiceBasePath = this.baseContextPath + this.appConfigService.configData.api.authService.urlPrefix;
    this.themingServiceBasePath = this.baseContextPath + this.appConfigService.configData.api.themingService.urlPrefix;
  }

  getBaseContextPath(): string {
    return this.baseContextPath;
  }

  getAuthServiceLoginUrl(configurationId: string, lang: string): string {
    return `${this.authServiceBasePath}${this.appConfigService.configData.api.authService.loginPath}`
      .replace(':configurationId', configurationId)
      .replace(':lang', lang);
  }

  getAuthServiceLogoutUrl(): string {
    return `${this.authServiceBasePath}${this.appConfigService.configData.api.authService.logoutPath}`;
  }

  getAuthServiceSignUrl(assessmentWrapperId: string, lang: string): string {
    return `${this.authServiceBasePath}${this.appConfigService.configData.api.authService.signPath}`
      .replace(':assessmentWrapperId', assessmentWrapperId)
      .replace(':lang', lang);
  }

  getAuthServiceVerifyUrl(assessmentId: string): string {
    return `${this.authServiceBasePath}${this.appConfigService.configData.api.authService.verifyPath}`.replace(
      ':assessmentId',
      assessmentId
    );
  }

  getAuthServiceTokenRefresh(assessmentId: string): string {
    return `${this.authServiceBasePath}${this.appConfigService.configData.api.authService.tokenRefreshPath.replace(
      ':assessmentId',
      assessmentId
    )}`;
  }

  getAssessmentServiceBaseUrl(assessmentWrapperId?: string): string {
    let url: string = this.baseContextPath + this.appConfigService.configData.api.assessmentService.urlPrefix;

    return assessmentWrapperId ? url.replace(':assessmentWrapperId', assessmentWrapperId) : url.replace(':assessmentWrapperId/', '');
  }

  getAssessmentServiceIsCompleteUrl(assessmentWrapperId: string): string {
    let url: string =
      this.baseContextPath +
      this.appConfigService.configData.api.assessmentService.urlPrefix +
      this.appConfigService.configData.api.assessmentService.isCompletePath;

    return url.replace(':assessmentWrapperId', assessmentWrapperId);
  }

  getAssessmentServiceInitUrl(assessmentWrapperId: string): string {
    let url: string =
      this.baseContextPath +
      this.appConfigService.configData.api.assessmentService.urlPrefix +
      this.appConfigService.configData.api.assessmentService.initPath;

    return url.replace(':assessmentWrapperId', assessmentWrapperId);
  }

  getAssessmentServiceProductViewUrl(assessmentWrapperId: string): string {
    let url: string =
      this.baseContextPath +
      this.appConfigService.configData.api.assessmentService.urlPrefix +
      this.appConfigService.configData.api.assessmentService.productViewPath;

    return url.replace(':assessmentWrapperId', assessmentWrapperId);
  }

  getAssessmentServiceRestartUrl(assessmentWrapperId: string): string {
    let url: string =
      this.baseContextPath +
      this.appConfigService.configData.api.assessmentService.urlPrefix +
      this.appConfigService.configData.api.assessmentService.restartPath;

    return url.replace(':assessmentWrapperId', assessmentWrapperId);
  }

  getAssessmentServiceResultUrl(assessmentWrapperId: string): string {
    let url: string =
      this.baseContextPath +
      this.appConfigService.configData.api.assessmentService.urlPrefix +
      this.appConfigService.configData.api.assessmentService.resultPath;

    return url.replace(':assessmentWrapperId', assessmentWrapperId);
  }

  getAssessmentServiceConfirmUrl(assessmentWrapperId: string): string {
    let url: string =
      this.baseContextPath +
      this.appConfigService.configData.api.assessmentService.urlPrefix +
      this.appConfigService.configData.api.assessmentService.confirmPath;

    return url.replace(':assessmentWrapperId', assessmentWrapperId);
  }

  getAssessmentServiceProcessUrl(assessmentWrapperId: string): string {
    let url: string =
      this.baseContextPath +
      this.appConfigService.configData.api.assessmentService.urlPrefix +
      this.appConfigService.configData.api.assessmentService.processPath;

    return url.replace(':assessmentWrapperId', assessmentWrapperId);
  }

  getAssessmentServiceChangeStateUrl(assessmentWrapperId: string): string {
    let url: string =
      this.baseContextPath +
      this.appConfigService.configData.api.assessmentService.urlPrefix +
      this.appConfigService.configData.api.assessmentService.changeStatePath;

    return url.replace(':assessmentWrapperId', assessmentWrapperId);
  }

  getUserContextServiceBaseUrl(userContextId?: string): string {
    let url: string = this.baseContextPath + this.appConfigService.configData.api.userContextService.urlPrefix;

    return userContextId ? `${url}${userContextId}/` : url;
  }

  getUserContextServiceAuthenticatedUrl(): string {
    return (
      this.baseContextPath +
      this.appConfigService.configData.api.userContextService.urlPrefix +
      this.appConfigService.configData.api.userContextService.authenticatedPath
    );
  }

  getUserContextServiceConsentUrl(userContextId: string): string {
    let url: string =
      this.baseContextPath +
      this.appConfigService.configData.api.userContextService.urlPrefix +
      this.appConfigService.configData.api.userContextService.consentPath;

    return url.replace(':userContextId', userContextId);
  }

  getUserContextServiceChangeStateUrl(userContextId: string): string {
    let url: string =
      this.baseContextPath +
      this.appConfigService.configData.api.userContextService.urlPrefix +
      this.appConfigService.configData.api.userContextService.changeStatePath;

    return url.replace(':userContextId', userContextId);
  }

  getUserContextServiceInternalPrepareUrl(userContextId: string): string {
    let url: string =
      this.baseContextPath +
      this.appConfigService.configData.api.userContextService.urlPrefix +
      this.appConfigService.configData.api.userContextService.internalPrepare;

    return url.replace(':userContextId', userContextId);
  }

  getThemingServiceCssUrl(configurationId: string): string {
    return `${this.themingServiceBasePath}${this.appConfigService.configData.api.themingService.cssPath}`.replace(
      ':configurationId',
      configurationId
    );
  }

  getThemingServiceLeftLogoUrl(configurationId: string): string {
    return `${this.themingServiceBasePath}${this.appConfigService.configData.api.themingService.logoPath.left}`.replace(
      ':configurationId',
      configurationId
    );
  }

  getThemingServiceRightLogoUrl(configurationId: string): string {
    return `${this.themingServiceBasePath}${this.appConfigService.configData.api.themingService.logoPath.right}`.replace(
      ':configurationId',
      configurationId
    );
  }

  getThemingServiceFaviconUrl(configurationId: string): string {
    return this.appConfigService.configData.api.themingService.faviconPath
      ? `${this.themingServiceBasePath}${this.appConfigService.configData.api.themingService.faviconPath}`.replace(
          ':configurationId',
          configurationId
        )
      : '';
  }

  getThemingServiceTranslationUrl(configurationId: string): string {
    return `${this.themingServiceBasePath}${this.appConfigService.configData.api.themingService.translationsPath}`.replace(
      ':configurationId',
      configurationId
    );
  }

  getThemingServiceLocaleUrl(): string {
    return `${this.themingServiceBasePath}${this.appConfigService.configData.api.themingService.localePath}`;
  }

  getDocumentServiceDocUrl(assessmentWrapperId: string): string {
    let url: string =
      this.baseContextPath +
      this.appConfigService.configData.api.documentService.urlPrefix +
      this.appConfigService.configData.api.documentService.docPath;

    return url.replace(':assessmentWrapperId', assessmentWrapperId);
  }

  getResourceServiceProductSetupUrl(lang: string): string {
    let url: string =
      this.baseContextPath +
      this.appConfigService.configData.api.resourceService.urlPrefix +
      this.appConfigService.configData.api.resourceService.productSetupPath;

    return url.replace(':lang', lang);
  }
}

import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AssessmentService } from '../../services/assessment-service/assessment.service';

@Injectable({ providedIn: 'root' })
export class AssessmentStateGuard {
  constructor(private assessmentService: AssessmentService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.assessmentService.getCurrentAssessmentWrapperId()) {
      return true;
    } else {
      return this.router.navigateByUrl('/initial-information');
    }
  }
}

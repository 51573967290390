import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppConfigService } from '../app-config-service/app-config.service';
import { UrlConfigService } from '../url-config-service/url-config.service';

@Injectable({
  providedIn: 'root',
})
export class ThemingService {
  constructor(private http: HttpClient, private appConfigService: AppConfigService, private urlConfigService: UrlConfigService) {}

  loadSupportedTranslations(): Observable<string[]> {
    return this.http.get<string[]>(this.urlConfigService.getThemingServiceTranslationUrl(this.appConfigService.configurationId));
  }

  getFaviconPath(): string {
    return this.urlConfigService.getThemingServiceFaviconUrl(this.appConfigService.configurationId);
  }
}

import { Component } from '@angular/core';
import { UtilService } from '../../services/util-service/util.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  constructor(private utilService: UtilService) {}

  shouldSingleElementBeDisplayed(translationKey: string): boolean {
    return this.utilService.isTranslationAvailable(translationKey);
  }

  isAtLeastOneTranslationAvailable(translationKeys: string[]): boolean {
    return translationKeys.some(translationKey => this.utilService.isTranslationAvailable(translationKey));
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { AppConfigService } from '../../services/app-config-service/app-config.service';
import { UserContextService } from '../../services/user-context-service/user-context.service';
import { AuthenticationService } from '../../services/authentication-service/authentication.service';
import { forkJoin, merge, Observable, Subject, timer } from 'rxjs';
import { Data, ResolveEnd, Router } from '@angular/router';
import { AutoLogoutServiceService } from '../../services/auto-logout-service/auto-logout-service.service';
import { UrlConfigService } from '../../services/url-config-service/url-config.service';
import { Title } from '@angular/platform-browser';
import { concatMap, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CookieKey } from '../../models/cookie-key.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  isLanguageSwitchPermitted: boolean;
  availableLanguages: string[];
  logoLeftSrc: string;
  logoRightSrc: string;
  timeToTimeout$: Observable<Date>;
  progress: string;
  showSaveAndLogoutButton: boolean;
  askSaveQuestion: boolean;
  isNavbarMenuCollapsed: boolean = true;
  unsubscribe$ = new Subject();
  currentLanguage: string;
  initialLang$ = new Subject<string>();

  constructor(
    private translateService: TranslateService,
    private cookieService: CookieService,
    private appConfigService: AppConfigService,
    private userContextService: UserContextService,
    private authService: AuthenticationService,
    private router: Router,
    private autoLogoutService: AutoLogoutServiceService,
    private urlConfigService: UrlConfigService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    const unsubscribeTimer$ = new Subject();
    const languageOnChange$: Observable<string> = this.translateService.onLangChange.pipe(
      map(newLanguageSelection => newLanguageSelection.lang)
    );

    merge(this.initialLang$, languageOnChange$)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap(lang => (this.currentLanguage = lang))
      )
      .subscribe();

    if (!this.translateService.currentLang) {
      timer(100, 100)
        .pipe(takeUntil(merge(unsubscribeTimer$, this.unsubscribe$)))
        .subscribe(() => {
          if (this.translateService.currentLang) {
            this.initialLang$.next(this.translateService.currentLang);
            unsubscribeTimer$.next(undefined);
            unsubscribeTimer$.complete();
          }
        });
    } else {
      this.initialLang$.next(this.translateService.currentLang);
    }

    this.availableLanguages = this.translateService.getLangs();
    this.logoLeftSrc = this.getLeftLogoUrl();
    this.logoRightSrc = this.getRightLogoUrl();

    const updateTimeToTimeout$ = this.autoLogoutService.updatedTimeToTimeout.pipe(
      map(timeLeftTillTimeout => new Date(timeLeftTillTimeout))
    );
    this.timeToTimeout$ = updateTimeToTimeout$.pipe(startWith(new Date(this.appConfigService.configData.secondsToAutoLogout * 1000)));

    let routerEventsData$: Observable<Data> = this.router.events.pipe(
      takeUntil(this.unsubscribe$),
      filter(a => a instanceof ResolveEnd),
      map(b => (b as ResolveEnd).state.root.firstChild.data)
    );

    routerEventsData$
      .pipe(
        tap(data => {
          this.progress = data.hasOwnProperty('progress') ? (data['progress'] as string) : '0%';
          this.isLanguageSwitchPermitted = data.hasOwnProperty('isLanguageSwitchPermitted')
            ? (data['isLanguageSwitchPermitted'] as boolean)
            : false;
          this.showSaveAndLogoutButton = data.hasOwnProperty('isSaveAndLogoutButtonPermitted')
            ? (data['isSaveAndLogoutButtonPermitted'] as boolean)
            : false;
          this.askSaveQuestion = data.hasOwnProperty('askSaveQuestion') ? (data['askSaveQuestion'] as boolean) : false;
        })
      )
      .subscribe();

    routerEventsData$
      .pipe(
        concatMap(data =>
          this.translateService.onLangChange.pipe(
            switchMap(() => {
              return this.forkJoinTranslations(data['documentTitleRouteKey']);
            })
          )
        ),
        tap((translation: HeaderTranslation) => {
          this.setPageTitle(this.titleService, translation);
        })
      )
      .subscribe();

    const documentTitleRouteKey$: Observable<HeaderTranslation> = routerEventsData$.pipe(
      concatMap(data => {
        return this.forkJoinTranslations(data['documentTitleRouteKey']);
      })
    );

    merge(documentTitleRouteKey$)
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((translation: HeaderTranslation) => {
          this.setPageTitle(this.titleService, translation);
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.unsubscribe$.next(undefined);
    this.unsubscribe$.complete();
  }

  isLoggedIn(): boolean {
    return this.authService.isUserLoggedIn();
  }

  logout(forceQuestionnaireSave?: boolean): void {
    this.authService.logoutUser(false, !!forceQuestionnaireSave, this.askSaveQuestion).subscribe();
  }

  changeLanguage(selectedLanguageCode: string): void {
    this.translateService.use(selectedLanguageCode);
    this.cookieService.set(CookieKey.Lang, selectedLanguageCode);
  }

  hasGivenConsent(): boolean {
    return this.cookieService.check(CookieKey.CookieConsent) && Boolean(this.cookieService.get(CookieKey.CookieConsent));
  }

  giveConsent(): void {
    this.cookieService.set(CookieKey.CookieConsent, 'true');
  }

  loggedInMessage(): string {
    return this.userContextService.loggedInUser?.loggedInMessage || '';
  }

  private forkJoinTranslations(documentTitleKey: string): Observable<HeaderTranslation> {
    return forkJoin({
      documentTitleMain: this.translateService.get('document.titles.main'),
      documentTitleRoute: this.translateService.get(documentTitleKey),
      documentTitleSeparator: this.translateService.get('document.titles.separator'),
    });
  }

  private setPageTitle(
    titleService: Title,
    translations: {
      documentTitleMain: string;
      documentTitleRoute: string;
      documentTitleSeparator: string;
    }
  ) {
    titleService.setTitle(`${translations.documentTitleMain} ${translations.documentTitleSeparator} ${translations.documentTitleRoute}`);
  }

  private getLeftLogoUrl(): string {
    if (this.appConfigService.configData.api.themingService.logoPath?.left) {
      return this.urlConfigService.getThemingServiceLeftLogoUrl(this.appConfigService.configurationId);
    }

    return null;
  }

  private getRightLogoUrl(): string {
    if (this.appConfigService.configData.api.themingService.logoPath?.right) {
      return this.urlConfigService.getThemingServiceRightLogoUrl(this.appConfigService.configurationId);
    }

    return null;
  }
}

class HeaderTranslation {
  documentTitleMain: string;
  documentTitleRoute: string;
  documentTitleSeparator: string;
}

<app-language-attribute></app-language-attribute>
<app-header class="rsp-header"></app-header>
<main class="rsp-container" role="main" *ngIf="areTranslationsAvailable">
  <div class="content">
    <router-outlet></router-outlet>
  </div>
</main>
<app-footer class="rsp-footer" *ngIf="areTranslationsAvailable"></app-footer>

<app-spinner-overlay *ngIf="!areTranslationsAvailable || (spinnerOverlayService.showSpinnerOverlayDelayed$ | async)"></app-spinner-overlay>

import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthenticationService } from '../../services/authentication-service/authentication.service';
import { AppConfigService } from '../../services/app-config-service/app-config.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilService } from '../../services/util-service/util.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../modal/modal.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
})
export class AuthenticateComponent implements OnInit {
  iframeSrc: SafeResourceUrl;
  rspAuthenticateTitle = 'Authenticate';

  constructor(
    private authenticationService: AuthenticationService,
    private config: AppConfigService,
    private translate: TranslateService,
    private utils: UtilService,
    public sanitizer: DomSanitizer,
    public route: ActivatedRoute,
    private modalService: NgbModal,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.iframeSrc = sanitizer.bypassSecurityTrustResourceUrl('');
  }

  ngOnInit(): void {
    this.authenticationService.getLoginPath(this.config.configurationId, this.translate.currentLang).subscribe(loginPath => {
      if (this.config.configData.externalAuth) {
        window.location.href = loginPath;
      } else {
        this.iframeSrc = this.utils.handleIFrameUrl(loginPath);
      }
    });
    this.route.queryParams.subscribe(p => {
      if (p?.error) {
        this.showErrorModal(p.error);
      }
    });
  }

  showErrorModal(modalBodyKey: string): NgbModalRef {
    let modal: NgbModalRef = this.modalService.open(ModalComponent, {});
    modal.componentInstance.modalBodyKey = modalBodyKey;
    modal.componentInstance.modalTitleKey = 'rspModalGeneralHeading';
    modal.componentInstance.modalButtonCloseKey = 'rspModalButtonClose';
    return modal;
  }
}

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  template: `
    <div class="modal-header alert {{ getAlertClass() }}">
      <h3 class="modal-title" id="modal-title">
        {{ headline | translate }}
      </h3>
      <button
        type="button"
        class="btn-close"
        [attr.aria-label]="'rspModalCloseButtonAriaLabel' | translate"
        (click)="activeModal.dismiss()"
      ></button>
    </div>
    <p class="modal-body" id="modal-body">
      {{ contentText | translate }}
    </p>
  `,
})
export class QuestionnaireNotificationModalComponent {
  @Input() headline: string;
  @Input() contentText: string;
  @Input() messageType: string;

  constructor(public activeModal: NgbActiveModal) {}

  getAlertClass(): string {
    switch (this.messageType) {
      case 'info':
        return 'alert-info';
      case 'warning':
        return 'alert-warning';
      case 'error':
        return 'alert-danger';
      default:
        return '';
    }
  }
}

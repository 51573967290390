import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KbProductList } from '../../models/kb-product-list.model';
import { UrlConfigService } from '../url-config-service/url-config.service';

@Injectable({
  providedIn: 'root',
})
export class ResourceService {
  constructor(private http: HttpClient, private urlConfigService: UrlConfigService) {}

  getProductSetup(language: string): Observable<KbProductList> {
    return this.http.get<KbProductList>(this.urlConfigService.getResourceServiceProductSetupUrl(language));
  }
}

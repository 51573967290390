import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { StateService } from '../../services/state-service/state.service';
import { ErrorInfo } from '../../models/error-info.model';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../../components/modal/modal.component';
import { SpinnerOverlayService } from '../../services/spinner-overlay/spinner-overlay.service';
import { AppConfigService } from '../../services/app-config-service/app-config.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  private CONFIGURATION_ID_ERROR_KEY = 'configurationIdError';

  constructor(
    private router: Router,
    private stateService: StateService,
    private modalService: NgbModal,
    private spinnerService: SpinnerOverlayService,
    private appConfigService: AppConfigService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinnerService.show();

    return next.handle(req).pipe(
      tap({
        next: (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.spinnerService.hide();
          }
        },
        error: error => {
          this.spinnerService.hide();
        },
      }),
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case HttpStatusCode.Unauthorized: {
            this.router.navigateByUrl('/authenticate').then();
            break;
          }
          case HttpStatusCode.Forbidden: {
            this.router.navigateByUrl(this.stateService.getRedirectPathByAssessmentState((<ErrorInfo>error.error).state)).then();
            break;
          }
          case HttpStatusCode.NotFound: {
            this.showErrorModal(this.errorModalTranslationKey(error)).result.then(result => {
              this.router.navigateByUrl('/welcome').then();
            });
            break;
          }
          case HttpStatusCode.InternalServerError: {
            this.router.navigateByUrl('/error').then();
            break;
          }
          case HttpStatusCode.BadRequest: {
            if (error.error?.key === this.CONFIGURATION_ID_ERROR_KEY) {
              console.error(error.error.message);
              // prohibit incorrect configurationIds to be stored so that the user can go back to the welcome page using the default ID
              this.appConfigService.deleteStoredConfigurationId(error.error.configurationId);
              break;
            }

            this.showErrorModal(this.errorModalTranslationKey(error));
            break;
          }
          default: {
            this.showErrorModal(this.errorModalTranslationKey(error));
            break;
          }
        }
        return throwError(() => new Error(error.message));
      })
    );
  }

  errorModalTranslationKey(error: HttpErrorResponse): string {
    if (error.error instanceof ErrorInfo) {
      return error.error.translationKey;
    } else {
      return 'rspModalGeneralBody';
    }
  }

  showErrorModal(modalBodyKey: string): NgbModalRef {
    let modal: NgbModalRef = this.modalService.open(ModalComponent, {});
    modal.componentInstance.modalBodyKey = modalBodyKey;
    modal.componentInstance.modalTitleKey = 'rspModalGeneralHeading';
    modal.componentInstance.modalButtonCloseKey = 'rspModalButtonClose';
    return modal;
  }
}

<div class="flex-wrapper">
  <div class="center-page">
    <h1>
      {{ 'rspAuthenticateTitle' | translate }}
    </h1>
    <iframe *ngIf="iframeSrc" id="authFrame" title="{{ rspAuthenticateTitle }}" name="E-Ident" [src]="iframeSrc"> </iframe>
    <div class="d-flex justify-content-start">
      <button routerLink="/welcome" routerLinkActive="active" class="btn btn-secondary">
        {{ 'rspAuthenticateButtonBack' | translate }}
      </button>
    </div>
  </div>
</div>

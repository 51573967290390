import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  constructor(private sanitizer: DomSanitizer, private translateService: TranslateService) {}

  handleIFrameUrl(path: string): SafeResourceUrl {
    if (path.startsWith('{origin}')) {
      let urlToSanitize: string = path.replace(
        '{origin}',
        window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
      );
      let sanitizedUrl: SafeUrl = this.sanitizer.sanitize(SecurityContext.URL, urlToSanitize);
      // You cannot use a SafeUrl with an iFrame.
      // Instead, a SafeResourceUrl must be used, but it cannot be created directly using the sanitize function.
      // Via this workaround we ensure that we pass a sanitized url.
      return this.sanitizer.bypassSecurityTrustResourceUrl(sanitizedUrl.toString());
    } else if (path.startsWith('/')) {
      window.location.assign(window.location.href.replace(/\/#.*/g, '') + path);
      return undefined;
    } else {
      let sanitizedUrl: SafeUrl = this.sanitizer.sanitize(SecurityContext.URL, path);
      // You cannot use a SafeUrl with an iFrame.
      // Instead, a SafeResourceUrl must be used, but it cannot be created directly using the sanitize function.
      // Via this workaround we ensure that we pass a sanitized url.
      return this.sanitizer.bypassSecurityTrustResourceUrl(sanitizedUrl.toString());
    }
  }

  isTranslationAvailable(translationKey: string): boolean {
    const translationValue = this.translateService.instant(translationKey);
    return !!(translationValue && translationValue !== translationKey);
  }
}

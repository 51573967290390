<div class="modal-header">
  <h4 class="modal-title" id="restart-assessment-title">
    {{ modalTitleKey | translate }}
  </h4>
  <button
    type="button"
    class="btn-close"
    [attr.aria-label]="'rspModalCloseButtonAriaLabel' | translate"
    (click)="activeModal.dismiss('abort')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  {{ modalBodyKey | translate }}
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('close')">
    {{ modalButtonCloseKey | translate }}
  </button>
  <button type="button" class="btn btn-outline-dark" *ngIf="modalButtonCancelKey" (click)="activeModal.dismiss('close')">
    {{ modalButtonCancelKey | translate }}
  </button>
</div>

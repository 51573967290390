<footer class="container-fluid footer">
  <div class="row rsp-container">
    <div id="footer-org" class="col-md-4 col-xs-12 px-0">
      <dl *ngIf="isAtLeastOneTranslationAvailable(['rspFooterOrgLabel', 'rspFooterOrgNumber', 'rspFooterOrgNumber2'])">
        <dt *ngIf="shouldSingleElementBeDisplayed('rspFooterOrgLabel')">
          {{ 'rspFooterOrgLabel' | translate }}
        </dt>
        <dd *ngIf="isAtLeastOneTranslationAvailable(['rspFooterOrgNumber', 'rspFooterOrgNumber2'])">
          <p>
            <ng-container *ngIf="shouldSingleElementBeDisplayed('rspFooterOrgNumber')">
              {{ 'rspFooterOrgNumber' | translate }}<br />
            </ng-container>
            <ng-container *ngIf="shouldSingleElementBeDisplayed('rspFooterOrgNumber2')">
              {{ 'rspFooterOrgNumber2' | translate }}
            </ng-container>
          </p>
        </dd>
      </dl>
    </div>
    <div id="footer-address" class="col-md-4 col-xs-12 px-0">
      <dl
        *ngIf="
          isAtLeastOneTranslationAvailable([
            'rspFooterAddressLabel',
            'rspFooterAddress1',
            'rspFooterAddress2',
            'rspFooterAddress3',
            'rspFooterAddress4'
          ])
        "
      >
        <dt *ngIf="shouldSingleElementBeDisplayed('rspFooterAddressLabel')">
          {{ 'rspFooterAddressLabel' | translate }}
        </dt>
        <dd *ngIf="isAtLeastOneTranslationAvailable(['rspFooterAddress1', 'rspFooterAddress2', 'rspFooterAddress3', 'rspFooterAddress4'])">
          <p>
            <ng-container *ngIf="shouldSingleElementBeDisplayed('rspFooterAddress1')">
              {{ 'rspFooterAddress1' | translate }}<br />
            </ng-container>
            <ng-container *ngIf="shouldSingleElementBeDisplayed('rspFooterAddress2')">
              {{ 'rspFooterAddress2' | translate }}<br />
            </ng-container>
            <ng-container *ngIf="shouldSingleElementBeDisplayed('rspFooterAddress3')">
              {{ 'rspFooterAddress3' | translate }}<br />
            </ng-container>
            <ng-container *ngIf="shouldSingleElementBeDisplayed('rspFooterAddress4')">
              {{ 'rspFooterAddress4' | translate }}
            </ng-container>
          </p>
        </dd>
      </dl>
    </div>
    <div id="footer-phone-email" class="col-md-4 col-xs-12 px-0">
      <dl
        class="grid"
        *ngIf="
          isAtLeastOneTranslationAvailable([
            'rspFooterPhoneLabel',
            'rspFooterPhone',
            'rspFooterEmailLabel',
            'rspFooterEmail',
            'rspFooterAdditionalContactLabel',
            'rspFooterAdditionalContact'
          ])
        "
      >
        <ng-container *ngIf="isAtLeastOneTranslationAvailable(['rspFooterPhoneLabel', 'rspFooterPhone'])">
          <dt>
            {{ 'rspFooterPhoneLabel' | translate }}
          </dt>
          <dd>
            {{ 'rspFooterPhone' | translate }}
          </dd>
        </ng-container>
        <ng-container *ngIf="isAtLeastOneTranslationAvailable(['rspFooterEmailLabel', 'rspFooterEmail'])">
          <dt>
            {{ 'rspFooterEmailLabel' | translate }}
          </dt>
          <dd>
            {{ 'rspFooterEmail' | translate }}
          </dd>
        </ng-container>
        <ng-container *ngIf="isAtLeastOneTranslationAvailable(['rspFooterAdditionalContactLabel', 'rspFooterAdditionalContact'])">
          <dt>
            {{ 'rspFooterAdditionalContactLabel' | translate }}
          </dt>
          <dd>
            {{ 'rspFooterAdditionalContact' | translate }}
          </dd>
        </ng-container>
      </dl>
    </div>
  </div>
</footer>

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { QuestionnaireNotificationModalComponent } from '../../dialogs/questionaire-notification-modal';
import { Questionnaire, QuestionnaireNotification } from '../../models/assessment-questionaire.model';
import { AssessmentService } from '../../services/assessment-service/assessment.service';
import { AppConfigService } from '../../services/app-config-service/app-config.service';
import { AuthenticationService } from '../../services/authentication-service/authentication.service';
import { CepConfigurationModel } from '../../models/cep-configuration.model';
import { CepTranslationModel } from '../../models/cep-translation.model';
import { CepPaginationMode } from '../../models/cep-pagination-mode.model';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
})
export class AssessmentComponent implements OnDestroy, OnInit {
  questionnaireUrl = 'src/vendor/reflex-cep-questionnaire2/questionnaire.js';
  activeAssessmentId = '';
  questionnaireConfig: CepConfigurationModel;
  isRtl = false;
  language = 'en';
  translations: CepTranslationModel;
  paginationMode: CepPaginationMode;
  activePageId;
  applicationData;
  @ViewChild('questionnaireComponent') questionnaire: ElementRef;
  renewToken: Function;
  summaryMode: boolean = false;
  summaryModeOnPageLoad: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private assessmentService: AssessmentService,
    private modalService: NgbModal,
    private router: Router,
    private appConfig: AppConfigService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.activeAssessmentId = this.route.snapshot.paramMap.get('assessmentId');
    this.language = this.route.snapshot.paramMap.get('lang');
    this.translations = this.appConfig.cepTranslation;
    this.questionnaireConfig = this.appConfig.cepConfiguration;
    if (this.route.snapshot.paramMap.get('readonly') === 'true') {
      this.summaryModeOnPageLoad = true;
    }
    this.renewToken = (): Promise<null | void> => {
      return this.authenticationService.refreshCepTokenAsPromise(this.activeAssessmentId);
    };
    this.paginationMode = this.appConfig.configData.questionnaire?.paginationMode || CepPaginationMode.Questionnaire;
  }

  ngOnDestroy() {
    this.assessmentService.publishQuestionnaire(undefined);
  }

  onNotification(notificationData: QuestionnaireNotification) {
    if (notificationData.type && notificationData.type === 'error') {
      if (notificationData.error?.status > 500) {
        this.router.navigate(['/error']);
      } else {
        const modalRef = this.modalService.open(QuestionnaireNotificationModalComponent, {
          ariaLabelledBy: 'modal-title',
          centered: true,
        });
        modalRef.componentInstance.headline = notificationData.headline;
        modalRef.componentInstance.contentText = notificationData.messagetext;
        modalRef.componentInstance.messageType = notificationData.type;
      }
    }
  }

  onQuestionnaireInit() {
    if (this.questionnaire) {
      this.cepInitialSummaryModeWorkaround();
      this.assessmentService.publishQuestionnaire(<Questionnaire>this.questionnaire.nativeElement);
    }
  }

  onAssessmentFinished() {
    this.assessmentService.completeAssessment(this.assessmentService.getCurrentAssessmentWrapperId()).subscribe(() => {
      this.summaryMode = true;
      window.scroll(0, 0);
    });
  }

  editQuestionnaire() {
    this.summaryMode = false;
    window.scroll(0, 0);
  }

  goToConfirmPage() {
    this.router.navigate(['/confirm', this.activeAssessmentId, { lang: this.language }]);
  }

  // an initialization of the CEP questionnaire in summary mode is not possible as of CEP-22.0.0-RC6
  // so we have to wait for the questionnaire initialize event and instantly activate the summary mode
  // cases: come back from the confirm page to the assessment page
  private cepInitialSummaryModeWorkaround(): void {
    if (this.summaryModeOnPageLoad) {
      this.summaryMode = true;
    }
  }
}
